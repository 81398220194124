import Header from "../Header/Header"
import './ItemManagement.css'
// import TreeView from "../TreeView/TreeView"
import TreeViewer from "../TreeView/TreeView"


export default function ItemManagement(){
    return(
        <div>
            <Header></Header>
            <TreeViewer></TreeViewer>
        </div>
    )
}