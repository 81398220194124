import { useEffect , useState } from 'react';
import './Header.css'
import { Link,useLocation } from 'react-router-dom'
import UserInfo from '../UserInfo/UserInfo.jsx'




const Header = () => {
    const CurrentLocation = useLocation();
    useEffect(()=>{
    },[CurrentLocation])
    const isLogin = UserInfo().isLogin;
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const Logout = () => {
        document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.history.pushState(null, '', window.location.href);
        window.location.href = '/login';
        setIsLoggedIn(false)
        console.log(isLoggedIn)
        alert("로그아웃 완료")
    }
    const Login = () =>{
        window.location.href = '/login';

    }



    return (
        <div className='header'>
            <h1><Link to='/Home'><img className='logo' src={process.env.PUBLIC_URL+`/moongcle_icon2-removebg.png`}></img></Link></h1>
            <div className='linkBtn'>   
                <li className={`${CurrentLocation.pathname === '/ItemManagement' ? 'on' : ''} header_li`}>
                    <Link to='/ItemManagement'>문제 관리</Link>
                </li>
                <li className={`${CurrentLocation.pathname === '/ExamReport' ? 'on':''} header_li`}>
                    <Link to='/ExamReport'>성적 관리</Link>
                </li>
                <li className={`${CurrentLocation.pathname === '/ClassManagement' ? 'on':''} header_li`}>
                    <Link to='/ClassManagement'>클래스 관리</Link>
                </li>
                <li className={`${CurrentLocation.pathname === '/TestCreation' ? 'on':''} header_li`}>
                    <Link to='/TestCreation'>시험지 출제</Link>
                </li>
            </div>
            <div>
                {isLogin ? (<button className='btnTop' onClick={Logout}>로그아웃</button>) : (<button onClick={Login}className='btnTop'>로그인</button>)}
            </div>
        </div>
    );
}

export default Header

