import React from 'react';
import './App.css';
import LoginForm from './Components/LoginForm/LoginForm';
import Home from './Components/Home/Home';
import TestCreation from './Components/TestCreation/TestCreation';
import ExamReport from './Components/ExamReport/ExamReport';
import ItemManagement from './Components/ItemManagement/ItemManagement';
import ClassManagement from './Components/ClassManagement/ClassManagement';
import StudentMarking from './Components/StudentMarking/StudentMarking';
import StudentCheck from './Components/StudentMarking/StudentCheck';
import UserInfo from './Components/UserInfo/UserInfo.jsx'
import Admin from './Components/admin/Admin.jsx'
import { BrowserRouter, Routes ,Route , Navigate} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { useState, createContext , useEffect , } from "react";

export const AppContext = createContext();
export const back_ip_url = "http://localhost:3001"

function App(){
  const { userInfo, isLogin } = UserInfo();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 로딩 상태 관리: isLogin이 확정된 후에만 렌더링
    if (typeof isLogin !== 'undefined') {
      setIsLoading(false);
    }
  }, [isLogin]);

  if (isLoading) {
    return <div>Loading...</div>; // 로딩 중 표시할 내용
  }

  console.log('isLogin',userInfo)
  console.log('isLogin',isLogin)
  return(
    <CookiesProvider>
    <BrowserRouter>
      <Routes>
        {isLogin ? (
          <>
            <Route path='/home' element={<Home/>}></Route>
            <Route path='/TestCreation' element={<TestCreation/>}></Route>
            <Route path='/ExamReport' element={<ExamReport/>}></Route>
            <Route path='/ItemManagement' element={<ItemManagement/>}></Route>
            <Route path='/ClassManagement' element={<ClassManagement/>}></Route>
            <Route path='/Admin' element={<Admin/>}></Route>    
          </>
        ):(
          <Route path="*" element={<Navigate to="/" />} />
        )}
        <Route path='/StudentCheck' element={<StudentCheck/>}></Route>
        <Route path='/StudentMarking' element={<StudentMarking/>}></Route> 
        <Route path='/' element={<LoginForm/>}></Route>
        <Route path='/login' element={<LoginForm/>}></Route>
      </Routes>
    </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;