import React ,{ useState , useEffect, useRef } from 'react';
import './TreeView.css';
import axios from 'axios';
import UserInfo from '../UserInfo/UserInfo.jsx'
import TreeView from 'react-accessible-treeview';
import cx from "classnames";
import Modal from 'react-modal'
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { FaFileAlt ,FaRegPlusSquare,FaFileUpload  } from "react-icons/fa";
import { FaRegFolder, FaRegFolderOpen ,FaRegCheckSquare , FaRegWindowClose } from "react-icons/fa";
import { IoMdArrowDropright,IoMdRefreshCircle,IoMdClose } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { RiFolderTransferFill } from "react-icons/ri";


const TreeViewer = () =>{
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const [newTreeName,setNewTreeName] = useState('');
    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;
    const [checkedTree, setCheckedTree] = useState('')
    const [checkedTreeId, setCheckedTreeId] = useState(0)
    const [checkedTreeId2, setCheckedTreeId2] = useState(0)
    const [treeData, setTreeData] = useState(null);
    const [treeDataOrigin, setTreeDataOrigin] = useState(null);
    const [treeState, setTreeState] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [count,setCount] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [lengthOfCheck,setLengthOfCheck]= useState(0);
    const isFirstRender = useRef(true);



    //작업후 폴더 열리게하기
    const [expandedIds,setExpandedIds]= useState('');
    const getAndSetIds = (values) => {
        // 값들을 처리하고 중복 제거
        const uniqueIds = new Set(
          values
            .filter(val => !!val.trim())  // 빈 값 제거
            .map((x) => {
              const trimmedValue = x.trim(); // 공백 제거
              // 숫자 확인 및 변환
              return isNaN(trimmedValue) ? trimmedValue : parseInt(trimmedValue);
            })
        );
      
        // Set 객체를 배열로 변환하여 setExpandedIds에 전달
        setExpandedIds(Array.from(uniqueIds));
      };
    //드롭다운
    const [schoolGrade, setSchoolGrade] = useState('');
    const [newSchoolGrade, setNewSchoolGrade] = useState('');

    const school_grade = [
        { id: 15, name: "=============" },
        { id: 1, name: "초등학교 1학년" },
        { id: 2, name: "초등학교 2학년" },
        { id: 3, name: "초등학교 3학년" },
        { id: 4, name: "초등학교 4학년" },
        { id: 5, name: "초등학교 5학년" },
        { id: 6, name: "초등학교 6학년" },
        { id: 13, name: "=============" },
        { id: 7, name: "중학교 1학년" },
        { id: 8, name: "중학교 2학년" },
        { id: 9, name: "중학교 3학년" },
        { id: 14, name: "=============" },
        { id: 10, name: "고등학교 1학년" },
        { id: 11, name: "고등학교 2학년" },
        { id: 12, name: "고등학교 3학년" }
    ];
    //학년별로 보이게하기
    const searchByGrade = () =>{
        console.log("schoolGrade",schoolGrade)
        if(schoolGrade == 0){
            setTreeData(treeDataOrigin)
        }else{
            console.log(schoolGrade)
            const filteredData = treeDataOrigin.filter(
            item => item.id === 0 || (item.grade == schoolGrade))
            setTreeData(filteredData);
        }
    }
    //로그 체크
    const LogCheck = () =>{
        console.log("==로그 체크==")

    }

    useEffect(() => {
        if (isLogin) {  // 로그인 상태인지 확인
            const timer = setTimeout(() => {
                // 서버로부터 학생, 수강반 데이터 가져오기
                fetchTreeData();
            }, 100); // 0.1초 뒤에 실행

            return () => clearTimeout(timer);
        }
    }, [userInfo]);

    useEffect(() => {
        // 처음 렌더링 시에는 실행하지 않음
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // 서버로부터 트리 데이터 가져오기
        fetchTreeData();
    }, [count]);
  //트리 데이터 가져오기
    const fetchTreeData = () => {
        // 서버로 Axios 요청 보내기
        axios({
          method: "POST",
            url: `${back_ip_url}/getTreeData`,
            data: {userId : userInfo.id},
            withCredentials : true,
        })
        .then(response => {
            setTreeData(response.data);
            setTreeDataOrigin(response.data)
            setSchoolGrade(0)
        })
        .catch(error => {
            console.error('Error fetching tree data:', error);
        });
    };
  //데이터 트리구조로 변환
  const buildTree= (data) => {
    if(data != null){
                // id를 키로 사용하여 노드를 빠르게 찾을 수 있도록 맵을 생성합니다.
    const nodeMap = new Map();
    data.forEach(node => {
        node.children = []; // 각 노드에 자식 노드를 저장할 배열을 초기화합니다.
        nodeMap.set(node.id, node); // 노드를 id를 기반으로 맵에 추가합니다.
    });
    // 최상위 노드를 찾아서 트리 구조를 구축합니다.
    const tree = [];
    data.forEach(node => {
        if (node.parent === null) {
            tree.push(node); // 최상위 노드를 트리의 루트에 추가합니다.
        } else {
            // 부모 노드를 찾아서 자식 노드로 추가합니다.
            const parentNode = nodeMap.get(node.parent
            );
            if (parentNode) {
                parentNode.children.push(node);
            }
        }
    });
    return tree;
    }}

    // 변환된 데이터를 담을 배열 초기화
    let transformedData = [];
    const originalData = treeData;

    // treeData가 null이 아닐 때에만 실행
    if (originalData !== null) {
        // originalData를 순회하며 요소를 변환
        originalData.forEach(item => {
            // 새로운 요소를 만들어서 변환된 데이터에 추가
            const newItem = {
                name: item.name,
                id: item.id,
                parent: item.parent,
                children: [], // children은 초기화
                grade: item.grade,
                depth: item.depth,
                style_type : item.style_type
            };

            // parent가 현재 요소의 id와 같은 요소들의 id를 찾아서 children에 추가
            originalData.forEach(childItem => {
                if (childItem.parent === item.id) {
                    newItem.children.push(childItem.id);
                }
            });

            // 변환된 요소를 변환된 데이터 배열에 추가
            transformedData.push(newItem);
        });
    }
    //폴더와 문제 데이터 분리
    const hwp_file_ids = (treeDataOrigin || []).filter(item => item.style_type === 1).map(item => item.id);
    const folder_ids = (treeDataOrigin || []).filter(item => item.style_type !== 1).map(item => item.id);

    const isFolderChecked = folder_ids.includes(checkedTreeId);
    const isFileChecked = hwp_file_ids.includes(checkedTreeId);
    //트리추가

    const newTreeNameRef = useRef('');

    const handleInputChange = (e) => {
      newTreeNameRef.current = e.target.value;
    };
  
    const addNewTree = () => {
        // 서버로 Axios 요청 보내기
        const newTreeName = newTreeNameRef.current
        axios({
            method: "POST",
            url: `${back_ip_url}/addNewTree`,
            data: {
                treeId : checkedTreeId,
                treeName : newTreeName,
                userId : userInfo.id
            },
            withCredentials: true,
        })
        .then((response) => {
            if (response.status === 200) {
                fetchTreeData();
                setCount(count + 1);
                setNewTreeName(null);
                alert("폴더 추가 완료")
            }
        })
        .catch((err) => {
            alert('더 이상 트리를 추가할 수 없습니다');
        });
    };
    //문제집 추가
    const [modalIsOpen2,setModalIsOpen2] = useState(false)
    
    const openModal2 = () =>{
        setModalIsOpen2(true)
        setNewTreeName()
    }
    const closeModal2 = () =>{
        setModalIsOpen2(false)

    }

    const logcheck = () => {
        console.log("selectedFolder",selectedFolder)
        console.log("selectedItem",selectedItem)
        console.log(depth2)


    }

    const newTitleNameRef = useRef('');

    const handleInputChange2 = (e) => {
        newTitleNameRef.current = e.target.value;
    };
  
    const addNewTitle = () =>{
        const newTitleName = newTitleNameRef.current
        axios({
            method:"POST",
            url: `${back_ip_url}/addNewTitle`,
            data: {
                grade : newSchoolGrade,
                name : newTitleName,
                userId : userInfo.id
            },
            withCredentials: true,
        })
        .then(response =>{
            if (response.status === 200){
                alert(response.data);
                closeModal2()
                setLengthOfCheck(0)
            }
        }).catch(error => 
            console.error('Error:', error)
        );
        fetchTreeData();
        setNewTreeName(null)
        setCount(count+1)
    }


    ///트리삭제
    const deleteTree = () => {
        if(checkedTreeId !== 0){
            const checkedTreeData = transformedData.find(node => node.id === checkedTreeId)
            if(checkedTreeData.depth === 0){

            }else if(checkedTreeData.depth === 1){
                console.log("depth is 1")
            }else if(checkedTreeData.depth === 2){
                console.log("depth is 2")
            }else if(checkedTreeData.depth === 3){
                console.log("depth is 3")
            }
            else{
                console.log("depth is else")
            }
            const grandchildrenData = transformedData.filter(node => checkedTreeData.children.includes(node.id))
            const grandchildren = grandchildrenData.map(item => item.children).flat();
            console.log("grandchildren",grandchildren)
            console.log("grandchildren.children",grandchildrenData)
            console.log("checkedTreeData",checkedTreeData.children)
            console.log("depth is 0")
            console.log("test!")
            console.log("checkedTreeData.depth ",checkedTreeData.depth )
            console.log("checkedTreeId",checkedTreeId)
            const checkTree = treeData.find(node => node.id === checkedTreeId)
            const result = window.confirm(checkTree.name+"을(를) 삭제하시겠습니까? (하위트리도 삭제됩니다)");
            if(result){
                axios({
                    method: "POST",
                    url: `${back_ip_url}/deleteTree`,
                    data: {
                        depth : checkedTreeData.depth,
                        tree_id : checkedTreeId,
                        grandchildren_ids : grandchildren,
                        userId : userInfo.id,
                    },
                    withCredentials : true,
                })
                .then(response =>{
                    if (response.status === 200){
                        alert(response.data);
                        setLengthOfCheck(0)
                    }
                }).catch(error => 
                    console.error('Error:', error)
                );
                setCount(count+1)
                setCheckedTree(null)
                setNewTreeName(null)
                setCheckedTreeId(0)
            }
            }
        }
        
        
        

    //문제 업로드

    const [depth2,setDepth2] = useState()


    useEffect(() => {
        if (!treeData || treeData.length === 0) {
            return; // treeData가 없으면 아무 작업도 하지 않음
        }
    
        const checkTree = treeData.find(node => node.id === checkedTreeId);
        if (checkTree) {
            setDepth2(checkTree.depth)
        }
    }, [checkedTreeId, treeData]);
    const upload = () => {
        const result = window.confirm("선택된 폴더로 문제를 업로드하시겠습니까?");
        if (result) {
        var url = new URL('http://localhost:8000/manage_question/problemDecomposition');
        var params = { 
                    tree_id: checkedTreeId,
                    depth : depth2,
                    user_name : userInfo.id,
                    };
        fetch(url,{
            method : 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(params)
        })
        .then(response => 
            response.text(),
            setCount(count+1),
            fetchTreeData()
        )
        .then(data => alert(data))
        .catch(error => {console.error('Error:', error)
            alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
        });

        } else {
            // 사용자가 취소를 선택한 경우
            console.log("사용자가 취소를 선택했습니다.");
        } 
    }    
        //문제 확인 버튼

        const checkUploadedData = () => { 
            const result = window.confirm("한글로 문제를 확인하시겠습니까?");
            if (result) {
                var url = new URL('http://localhost:8000/manage_question/checkUploadedData');
                var params = { 
                            tree_id: checkedTreeId,
                            user_name : userInfo.id
                };
                fetch(url,{
                    method : 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(params)
                })
                .then(response => response.text())
                .then(data => alert(data))
                .catch(error => {console.error('Error:', error)
                    alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
                });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
            
        }
    //문제이동
    const [selectedItem,setSelectedItem] = useState()
    const [selectedFolder,setSelectedFolder] = useState()
    const [selectedItem2,setSelectedItem2] = useState()
    const [selectedFolder2,setSelectedFolder2] = useState()
    const selecteItem= () =>{    
        setSelectedItem(treeData.filter(item => item.id === checkedTreeId2))
    }
    const selecteFolder= () =>{
        setSelectedFolder(treeData.filter(item => item.id === checkedTreeId2))
    }
    const adjustLocation = () =>{
        const result = window.confirm("문제를 이동시키시겠습니까?");
        if (result) {
            axios({
                method: "POST",
                    url: `${back_ip_url}/adjustLocation`,
                    data: {
                        before : selectedItem,
                        after : selectedFolder,
                        userId : userInfo.id
                    },
                    withCredentials : true,
                })
                .then(response =>{
                    if(response.status === 200){
                        alert(response.data)
                    }
                })
                .catch(err=>{
                    alert('이동 중 에러 발생')
                })
                setCount(count+1)
                fetchTreeData();
        } else {
            // 사용자가 취소를 선택한 경우
            console.log("사용자가 취소를 선택했습니다.");
        }
    }
    //문제이동2
    const [modalIsOpen3,setModalIsOpen3] = useState(false)
    const [selectedItemIds,setSelectedItemIds] = useState([])
    const [targetFolder,setTargetFolder] = useState([])
    const data_itemIds = Array.from(selectedItemIds)
    const data_folderId = Array.from(targetFolder)

    const logcheck2 = () =>{
        console.log("selectedItemIds",selectedItemIds)
        console.log("targetFolder",targetFolder)
        console.log("selectedItemIds",Array.from(selectedItemIds))
        console.log("targetFolder",Array.from(targetFolder))
        console.log(treeData.filter(item => data_itemIds.includes(item.id)))
        console.log(treeData.filter(item => data_folderId.includes(item.id)))
    }
    const selectedItemData = () =>{    
        setSelectedItem2(treeData.filter(item => data_itemIds.includes(item.id)))
    }
    const selectedFolderData= () =>{
        setSelectedFolder2(treeData.filter(item => data_folderId.includes(item.id)))
    }
    const adjustLocation2 = () =>{
        selectedItemData()
        selectedFolderData()
        const result = window.confirm("문제를 이동시키시겠습니까?");
        if (result) {
            axios({
                method: "POST",
                    url: `${back_ip_url}/adjustLocation2`,
                    data: {
                        before : data_itemIds,
                        after : data_folderId,
                        data1 : treeData.filter(item => data_itemIds.includes(item.id)),
                        data2 : treeData.filter(item => data_folderId.includes(item.id)),
                        userId : userInfo.id
                    },
                    withCredentials : true,
                })
                .then(response =>{
                    if(response.status === 200){
                        alert(response.data)
                    }
                })
                .catch(err=>{
                    alert('이동중 에러발생')
                })
                setCount(count+1)
                fetchTreeData();
        } else {
            // 사용자가 취소를 선택한 경우
            console.log("사용자가 취소를 선택했습니다.");
        }
    }



const data = buildTree(treeData)
    // 트리 컴포넌트
  const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
      case "all":
        return <FaCheckSquare {...rest} />;
      case "none":
        return <FaSquare {...rest} />;
      case "some":
        return <FaMinusSquare {...rest} />;
      case "none_disabled":
        return <FaRegWindowClose {...rest} />;
      default:
        return null;
    }
  };

  const FolderIcon = ({ isOpen }) =>
    isOpen ? (
      <FaRegFolderOpen color="e8a87c" className="icon" />
    ) : (
      <FaRegFolder color="e8a87c" className="icon" />
    );
  
  const FileIcon = ({ style }) => {
    const style_type = style
    switch (style_type) {
      case 1:
        return <FaFileAlt  color="rgb(122, 124, 255)" className="icon" />;
    case "hwpx":
        return <FaFileAlt  color="rgb(122, 124, 255)" className="icon" />;    
      default:
        return <FolderIcon/>;
    }
    }

const [modalIsOpen,setModalIsOpen] = useState()
const openModal = () =>{
        setModalIsOpen(true)
        setSelectedItem()
        setSelectedFolder()
}
const openModal22 = () =>{
    setModalIsOpen3(true)
    setSelectedItem()
    setSelectedFolder()
}
  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return <IoMdArrowDropright className={classes} />;
  };
  //
    return isLogin ? (
    <div className='main'>
        <Modal className="Modal_s"isOpen={modalIsOpen2}>
            <div className='Modal_head'>
                <h3>문제집 추가</h3>
                <button className="xMark" onClick={closeModal2}><IoMdClose /></button>
            </div>
            <select className='selectTree' style={{width : '160px',height:'34px'}} value={newSchoolGrade} onChange={(e) => setNewSchoolGrade(e.target.value)}>
                <option value='0'>전체 보기</option>
                {school_grade.map((school) => (
                <option key={school.id} value={school.id}>
                    {school.name}
                </option>
                ))}
            </select>
            <input className='inputA' type='text' style={{width : '160px',height:'34px'}} placeholder='문제집 이름'onChange={handleInputChange2}></input>
        <button className='buttonA btnColorA confirm_btn' onClick={addNewTitle} disabled={newSchoolGrade == 0 || newTitleNameRef.current == ''}>문제집 추가</button>
        </Modal>
        <Modal className='Modal_moveItem' isOpen={modalIsOpen3} onRequestClose={() => setModalIsOpen3(false)}>
            <div>
                <div className='moveTree_top'>
                <div className='dropDown'>
                <div>
                    <select className='selectTree' style={{width : '160px'}} value={schoolGrade} onChange={(e) => setSchoolGrade(e.target.value)}>
                        <option value='0'>전체 보기</option>
                        {school_grade.map((school) => (
                        <option key={school.id} value={school.id}>
                            {school.name}
                        </option>
                        ))}
                    </select>
                </div>
                <button className="buttonB btnColorA" style={{width : '40px',height:'30px',top:'5px' ,marginLeft:'0px'}} onClick={searchByGrade}>검색</button>
                <button className='buttonA btnColorA confirm_btn' style={{width : '140px',height:'30px',top:'5px' ,marginLeft:'0px'}} onClick={adjustLocation2}>문제이동</button>
                <button onClick={logcheck2}>logcheck</button>
            </div>
            </div>
                <div className='moveTreeDiv'>
                    <div className='moveTree' style={{background : 'white'}}>
                        <h3>이동할 문제선택</h3>
                        <TreeView
                        data={transformedData}
                        aria-label="Data type Map tree"
                        selectedIds={selectedIds}
                        defaultDisabledIds={folder_ids}
                        multiSelect
                        propagateSelect
                        togglableSelect
                        defaultExpandedIds={[]}
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            getNodeProps,
                            level,
                            isDisabled,
                            handleSelect,
                            handleExpand,
                            isHalfSelected,
                            isSelected,
                            treeState,
                        }) => {
                            setSelectedItemIds(treeState.selectedIds)
                            setTreeState(treeState.element)
                            return (
                            <div
                                {...getNodeProps({ onClick: handleExpand })}
                                style={{
                                    marginLeft: 40 * (level - 1),
                                    opacity: isDisabled ? 0.8 : 1,
                                }}
                            >
                                <CheckBoxIcon
                                    className="checkbox-icon"
                                    onClick={(e) => {
                                        handleSelect(e);
                                        e.stopPropagation();
                                    }}
                                    variant={isHalfSelected ? "some" : isSelected ? "all" : isDisabled ? 'none_disabled':'none' }
                                />
                                {isBranch  ? (
                                    <FolderIcon isOpen={isExpanded} />
                                ) : (
                                    <FileIcon style={element.style_type} />
                                )}
                                <span className="name">
                                {element.name}
                                -
                                {element.id}
                                                    -
                                {element.style_type}
                                </span>
                            </div>
                            );
                        }}
                    />
                    </div>
                    <div className='moveTree' style={{background : 'gray'}}>
                        <h3>이동될 폴더선택</h3>
                        <TreeView
                        data={transformedData}
                        aria-label="Data type Map tree"
                        selectedIds={selectedIds}
                        defaultDisabledIds={hwp_file_ids}
                        togglableSelect
                        defaultExpandedIds={[]}
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            getNodeProps,
                            level,
                            isDisabled,
                            handleSelect,
                            handleExpand,
                            isHalfSelected,
                            isSelected,
                            treeState,
                        }) => {
                            setTargetFolder(treeState.selectedIds)
                            setTreeState(treeState.element)
                            return (
                            <div
                                {...getNodeProps({ onClick: handleExpand })}
                                style={{
                                    marginLeft: 40 * (level - 1),
                                    opacity: isDisabled ? 0.5 : 1,
                                }}
                            >
                                <CheckBoxIcon
                                    className="checkbox-icon"
                                    onClick={(e) => {
                                        handleSelect(e);
                                        e.stopPropagation();
                                    }}
                                    variant={isHalfSelected ? "some" : isSelected ? "all" : isDisabled ? 'none_disabled':'none' }
                                />
                                {isBranch  ? (
                                    <FolderIcon isOpen={isExpanded} />
                                ) : (
                                    <FileIcon style={element.style_type} />
                                )}
                                <span className="name">
                                {element.name}
                                -
                                {element.id}
                                                    -
                                {element.style_type}
                                </span>
                            </div>
                            );
                        }}
                    />
                    </div>
                </div>
            </div>
        </Modal>
        <div className='treeWindow' style={{height:'800px' , width:"690px"}}>
            <div className='dropDown'>
                <div>
                    <select className='selectTree' style={{width : '160px'}} value={schoolGrade} onChange={(e) => setSchoolGrade(e.target.value)}>
                        <option value='0'>전체 보기</option>
                        {school_grade.map((school) => (
                        <option key={school.id} value={school.id}>
                            {school.name}
                        </option>
                        ))}
                    </select>
                </div>
                <button className="buttonB btnColorA" style={{width : '40px',height:'30px',top:'5px' ,marginLeft:'0px'}} onClick={searchByGrade}>검색</button>
            </div>
        {treeData ? (
        <TreeView
            data={transformedData}
            aria-label="Data type Map tree"
            selectedIds={selectedIds}
            togglableSelect
            expandedIds={expandedIds}
            defaultExpandedIds={[]}
            nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                getNodeProps,
                level,
                isDisabled,
                handleSelect,
                handleExpand,
                isHalfSelected,
                isSelected,
                treeState,
            }) => {
                setCheckedTreeId(treeState.lastUserSelect)
                setTreeState(treeState.element)
                setLengthOfCheck(treeState.selectedIds.size)
                return (
                <div
                    {...getNodeProps({ onClick: handleExpand })}
                    style={{
                        marginLeft: 40 * (level - 1),
                      }}
                >
                    <CheckBoxIcon
                        className="checkbox-icon"
                        onClick={(e) => {
                            handleSelect(e);
                            e.stopPropagation();
                        }}
                        variant={isHalfSelected ? "some" : isSelected ? "all" : "none"}
                    />
                    {isBranch  ? (
                        <FolderIcon isOpen={isExpanded} />
                    ) : (
                        <FileIcon style={element.style_type} />
                    )}
                    <span className="name">
                    {element.name}
                    -
                    id:{element.id}
                    -
                    grade:{element.grade}
                    </span>
                </div>
                );
            }}
        />  
        ) : ('트리 불러오는중')}
        </div>
        <div className='treeBtn' id='control'>
            <button className="buttonB btnColorA" onClick={fetchTreeData}style={{marginTop:"30px"}}><IoMdRefreshCircle className='iconA'/> 트리 새로고침</button>
            <button className="buttonB btnColorA" onClick={openModal2}><FaRegPlusSquare className='iconA'/> 문제집 추가</button>
            <span>{checkedTree}</span>
            <div className='folderAdd'>
                <input className="inputA" type='text' style={{  border: "solid 1px #ffffff" ,margin : "10px 0px 10px 10px" ,height:"40px",}}placeholder='추가할 폴더이름'onChange={handleInputChange} ></input>
                {/* 버튼에 해당하는 함수 제작하면 됨 */}
                <button className="buttonB btnColorA" disabled={lengthOfCheck == 0 || isFolderChecked == false} title={isFolderChecked == true ? "폴더를 선택해주세요!" : ""} style={{ width: '77px', height : '40px',}} onClick={addNewTree}>추가</button>
            </div>
            <button className="buttonB btnColorA" disabled={lengthOfCheck == 0} title={lengthOfCheck == 0 ? "폴더 또는 문제를 선택해주세요!" : ""} onClick={deleteTree}><MdDeleteForever className='iconA' style={{ fontSize: '22px' }}/> 선택 폴더 삭제</button>
            <button className="buttonB btnColorA" disabled={lengthOfCheck == 0 || isFolderChecked == false} title={lengthOfCheck == 0 ? "폴더를 선택해주세요!" : ""} onClick={()=>upload()}><FaFileUpload className='iconA'  style={{ fontSize: '20px' }}/> 문제 업로드</button>
            <button className="buttonB btnColorA" disabled={lengthOfCheck == 0 || isFolderChecked == true} title={lengthOfCheck == 0 ? "문제를 선택해주세요!" : ""} onClick={() => checkUploadedData()}><FaRegCheckSquare className='iconA' style={{ fontSize: '20px'}}/> 문제 확인</button>
            <button className="buttonB btnColorA" onClick={openModal22}><RiFolderTransferFill className='iconA' style={{ fontSize: '20px' }}/> 문제 이동</button>

            <button onClick={LogCheck}>로그 확인</button>
        </div>
    </div>
    ): null;
}

export default TreeViewer