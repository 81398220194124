import { useEffect , useState } from "react";

export default function UserInfo(){
function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length+1));
        }
    }
    return null;
}
const [userInfo, setUserInfo] = useState({});
const [isLogin,setIsLogin] = useState(false);
useEffect(() => {
    // 쿠키에서 사용자 정보 가져오기
    const userInfo_de = getCookie('userInfo');
    if (userInfo_de) {
        const parsedUserInfo = JSON.parse(userInfo_de.substring(2));
        setUserInfo(parsedUserInfo);
        setIsLogin(true)
    }else{
        setIsLogin(false)
    }
}, []);
return {userInfo,isLogin,setIsLogin}
}