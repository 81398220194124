import React , {useState,useEffect} from 'react';
import './LoginForm.css';
import { FaUser,FaSchool,} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaRegIdCard } from "react-icons/fa6";
import { TbLock,TbLockCheck } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserInfo from '../UserInfo/UserInfo.jsx'


export default function Login({ setIsLogin, setUser }) {

  const activeEnter = (e) => {
    if(e.key === "Enter") {
      login();
    }
  }
  const back_ip_url = process.env.REACT_APP_SERVER_URL

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const [Rid, setRId] = useState('');
  const [Rpassword, setRPassword] = useState('');
  const [Remail, setREmail] = useState('');
  const [Rname, setRName] = useState('');
  const [Rworkname, setRWorkname] = useState('');

  const [Fid, setFId] = useState('');
  const [Vercode,setVercode]  = useState('');
  const [Vercode2,setVercode2]  = useState('qwer123');
  const [Fpassword, setFPassword] = useState('');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isLogin = UserInfo().isLogin;
  useEffect(() => {
    if (typeof isLogin !== 'undefined') {
        if (isLogin) {
            navigate('/home'); // 로그인된 경우 home 페이지로 리디렉트
        } else {
            setIsLoading(false); // 로그인되지 않은 경우 대기 상태 해제
        }
    }
}, [isLogin, navigate]);
  // 오류메세지 상태 저장
 const [idMessage, setIdMessage] = React.useState("");
 const [passwordConfirmMessage, setPasswordConfirmMessage] =
   React.useState("");
  const [passwordConfirmMessage2, setPasswordConfirmMessage2] =
   React.useState("");
 const [emailMessage, setEmailMessage] = React.useState("");
 const [vercodeMessage,setVercodeMessage]  = React.useState("");
 const [rightVercodeMessage,setRightVercodeMessage] = React.useState("");

 // 유효성 검사
 const [isId, setIsId] = React.useState(false);
 const [isPasswordConfirm, setIsPasswordConfirm] = React.useState(false);
 const [isEmail, setIsEmail] = React.useState(false);
 const [isVercode,setIsvercode] = React.useState(false);
 const [isRightVercode,setIsRightVercode] = React.useState(false);

 //아이디 이메일 중복체크
 const DupCheckId = () =>{
  axios({
    url: `${back_ip_url}/DupCheckId`,
    method: "POST",
    withCredentials: true,
    data:{
      id : Rid
    }
  }).then((result) => {
    if (result.status === 200) {
      setIdMessage("사용가능한 아이디 입니다.");
      setIsId(true);     
    }
  }).catch(error => {
    setIdMessage("다른 아이디를 사용해주세요.");
    setIsId(false)
  })
 }
 const DupCheckEmail = () =>{
  axios({
    url: `${back_ip_url}/DupCheckEmail`,
    method: "POST",
    withCredentials: true,
    data:{
      email : Remail
    }
  }).then((result) => {
    if (result.status === 200) {
    setEmailMessage("사용 가능한 이메일 입니다.");
    setIsEmail(true);  
    }
  }
  ).catch(error => {
    setEmailMessage("다른 이메일을 사용해주세요.");
    setIsEmail(false)
  })
 }
 //
 const onChangeId = (e) => {
  const currentId = e.target.value;
  setRId(currentId);
};
useEffect(() => {
  const idRegExp = /^[a-zA-z0-9]{4,12}$/;
  if(!idRegExp.test(Rid)){
    setIdMessage("4-12사이 대소문자 또는 숫자만 입력해 주세요!");
    setIsId(false);
  } else {
    DupCheckId()
  }
}, [Rid]);

useEffect(() => {
  const emailRegExp =
    /^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;

  if (!emailRegExp.test(Remail)) {
    setEmailMessage("이메일의 형식이 올바르지 않습니다!");
    setIsEmail(false);
  } else {
    DupCheckEmail();
  }
}, [Remail]);
//

//
const onChangeEmail = (e) => {
  const currentEmail = e.target.value;
  setREmail(currentEmail);
}
//
const onChangePasswordConfirm = (e) => {
  const currentPasswordConfirm = e.target.value;
  if (Rpassword !== currentPasswordConfirm) {
    setPasswordConfirmMessage("비밀번호가 똑같지 않습니다!");
    setIsPasswordConfirm(false);
  } else {
    setPasswordConfirmMessage("똑같은 비밀번호를 입력했습니다.");
    setIsPasswordConfirm(true);
  }
};
const onChangePasswordConfirm2 = (e) => {
  const currentPasswordConfirm = e.target.value;
  if (Fpassword !== currentPasswordConfirm) {
    setPasswordConfirmMessage2("비밀번호가 똑같지 않습니다!");
    setIsPasswordConfirm(false);
  } else {
    setPasswordConfirmMessage2("똑같은 비밀번호를 입력했습니다.");
    setIsPasswordConfirm(true);
  }
};
const onChangeVercode = (e) => {
  const currentVercode = e.target.value;
  if (Vercode2 == currentVercode){
    setRightVercodeMessage("새 비밀번호를 입력해주세요!!");
    setIsRightVercode(true);
  }else{
    setRightVercodeMessage("확인코드가 일치하지 않습니다!");
    setIsRightVercode(false);
  }
}
//
  const login = () => {
    axios({
      url: `${back_ip_url}/login`,
      method: "POST",
      withCredentials: true,
      data: {
        id: id,
        password: password,
      },
    }).then((result) => {
      if (result.status === 200) {
        window.open('/home', '_self');
      } else if (result.status === 202) {
        alert("아이디 또는 비밀번호를 확인해주세요");
      }else if (result.status === 203) {
        alert("사용 권한이 없는 계정입니다");
      } else {
        // 기타 상태 코드에 대한 처리
        console.error("서버 오류:", result.status);
      }
    }).catch((error) => {
      // 네트워크 오류 등의 경우에 대한 처리
      console.error("요청 실패:", error);
    });
  };

  const register = () => {
    if(isId && isEmail && isPasswordConfirm ){
    axios({
      url: `${back_ip_url}/register`,
      method: "POST",
      withCredentials: true,
      data: {
        id: Rid,
        password: Rpassword,
        email: Remail,
        name: Rname,
        workname: Rworkname,
      }
    }).then((response)=>{
      alert(response.data)
      switchBtn2()
    })
    }
    else{
      alert('다시 확인해주세요!')
    }
  }
  const sendEmail = () => {

    axios({
      url: `${back_ip_url}/sendEmail`,
      method: "POST",
      withCredentials: true,
      data:{
        id : Fid
      }
    }).then((result)=>{
      if (result.status === 200) {
        setVercodeMessage('이메일로 확인코드를 발송 했습니다!')
        setIsvercode(true)
        setVercode2(result.data.code)
      }else{
        setVercodeMessage('아이디를 다시 확인해주세요!')
        setIsvercode(false)
        
      }
    }
    )
  }
  const findPW = () => {
    if(isRightVercode && isPasswordConfirm){
      axios({
        url: `${back_ip_url}/changePassword`,
        method: "POST",
        withCredentials: true,
        data:{
          password : Fpassword,
          id : Fid,
        }
      }).then(response =>{
        if (response.status === 200){
            alert("비밀번호 변경 완료!");
            document.getElementById("inputElement").value = ""
            setPasswordConfirmMessage2(null)
            setRightVercodeMessage(null)
            setVercodeMessage(null)
            setVercode2('qwer123')
            setIsvercode(false)
            window.open('/login', '_self');
        }
    })
    }
  }
  
  function switchBtn(){
    document.getElementById('login_form').classList.replace('block', 'none');
    document.getElementById('register_form').classList.replace('none', 'block');
  }
  function switchBtn2(){
    document.getElementById('login_form').classList.replace('none', 'block');
    document.getElementById('register_form').classList.replace('block', 'none');
  }
  function switchBtn3(){
    document.getElementById('login_form').classList.replace('block', 'none');
    document.getElementById('find_form').classList.replace('none', 'block');
  }
  function switchBtn4(){
    document.getElementById('login_form').classList.replace('none', 'block');
    document.getElementById('find_form').classList.replace('block', 'none');
  }


  
  if (isLoading) {
    return <div>Loading...</div>; // 로딩 중 표시할 내용
  }
  return (
    <div className='wrapper'>
        {/* 로그인창 */}
      <div className='login_form block' id='login_form'>
        <h1>로그인</h1>
        <div className='input-box'>
            <input type='text' placeholder='사용자 ID' name="id" onChange={(e) => setId(e.target.value)}  value={id} required />
            <FaUser className='icon'/>
        </div>
        <div className='input-box'>
            <input onKeyDown={(e) => activeEnter(e)} type='password' placeholder='비밀번호' name="password" onChange={(e) => setPassword(e.target.value)}  value={password} required/>
            <TbLock className='icon'/>
        </div>
        <div className='forgot-pw'>
            <a className='switch' onClick={switchBtn3}>비밀번호를 잊어버리셨나요?</a>
        </div>
        <button id="submitButton" onClick={login}>로그인</button>
        <div className='register-link'>
            <p>계정이 없으신가요? <a className='switch' onClick={switchBtn}>회원가입</a></p>
        </div>
      </div>

        {/* 회원가입창 */}
      <div className='register_form none' id='register_form'>
        <h1>회원가입</h1>
        <div className='input-box'>  
            <input type='text' placeholder='사용자 ID' name="id" onChange={onChangeId} required></input>
            <FaUser className='icon'/>
            <p className={`message ${!isId ? 'no' : 'ok'}`}>{idMessage}</p>
        </div>
        <div className='input-box'>
            <input type='text' placeholder='이메일 주소' name="email" onChange={onChangeEmail} required></input>
            <MdMail className='icon'/>
            <p className={`message ${!isEmail ? 'no' : 'ok'}`}>{emailMessage}</p>
        </div>
        <div className='input-box'>
            <input type='password' placeholder='비밀번호 (4자리 이상)' name="password" minLength={4} maxLength={20} required onChange={(e) => setRPassword(e.target.value)}  value={Rpassword}></input>
            <TbLock className='icon'/>
        </div>
        <div className='input-box'>
            <input type='password' placeholder='비밀번호 확인' onChange={onChangePasswordConfirm}required></input>
            <TbLockCheck className='icon'/>
            <p className={`message ${!isPasswordConfirm ? 'no' : 'ok'}`}>{passwordConfirmMessage}</p>
        </div>
        <div className='input-box'>
            <input type='text' placeholder='사용자명' name="name" onChange={(e) => setRName(e.target.value)}  value={Rname} required></input>
            <FaRegIdCard className='icon'/>
        </div>
        <div className='input-box'>
            <input type='text' maxlength="10" placeholder='학원명' name="workname" onChange={(e) => setRWorkname(e.target.value)}  value={Rworkname} required></input>
            <FaSchool className='icon'/>
        </div>
        <button onClick={register}>회원가입</button>
        <div className='register-link'>
            <p>이미 계정이 있으신가요? <a className='switch' onClick={switchBtn2}>로그인</a></p>
        </div>
      </div>
      {/* 비밀번호 찾기 */}
      <div className='find_form none' id='find_form'>
        <h1>비밀번호 찾기</h1>
        <div className='input-box'>
            <input type='text' placeholder='사용자 ID' name="Fid" onChange={(e) => setFId(e.target.value)} required></input>
            <FaUser className='icon'/>
        </div>
        <button onClick={sendEmail}>확인코드 발송</button>
        <p className={`message ${!isVercode ? 'no' : 'ok'}`}>{vercodeMessage}</p>
        <div className='input-box'>
            <input type='text' placeholder='확인 코드' id='inputElement' name="verCode" onChange={onChangeVercode} required></input>
            <MdMail className='icon'/>
          <p className={`message ${!isRightVercode ? 'no' : 'ok'}`}>{rightVercodeMessage}</p>
        </div>
        <div className='input-box'>
            <input type='password' placeholder='새 비밀번호 (4자리 이상)' name="password" id='inputElement' minLength={4} maxLength={20} required onChange={(e) => setFPassword(e.target.value)}  value={Fpassword}></input>
            <TbLock className='icon'/>
        </div>
        <div className='input-box'>
            <input type='password' placeholder='새 비밀번호 재입력' onChange={onChangePasswordConfirm2} id='inputElement' required ></input>
            <TbLockCheck className='icon'/>
            <p className={`message ${!isPasswordConfirm ? 'no' : 'ok'}`}>{passwordConfirmMessage2}</p>
        </div>
        <button onClick={findPW}>비밀번호 변경</button>
        <a className='switch' onClick={switchBtn4}>로그인 페이지 이동</a>
      </div>

    </div>
  );
}