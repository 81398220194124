import { useEffect , useState } from 'react';
import './Admin.css'
import axios from 'axios';
import { Link,useLocation } from 'react-router-dom'
import UserInfo from '../UserInfo/UserInfo.jsx'




const Admin = () => {
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;
    const isAdmin = true
    

    const [userData,setUserData] = useState()
    const fetchUserData = () => {
        axios({
            method: "POST",
              url: `${back_ip_url}/fetchUserData`,
              withCredentials : true,
          })
          .then(response => {
            if(response.status === 200){
                setUserData(response.data);
            }
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    };
    useEffect(() => {
        // 데이터를 가져오는 비동기 함수 (예: API 호출)
        fetchUserData();
    }, []);

    const logCheck = () =>{
        console.log("log CHECK")
        console.log("userData",userData)
        console.log(selectedRow)
        console.log(userData[selectedRow].workname)
    }

    const [selectedRow, setSelectedRow] = useState();

    const handleRowClick = (index) => {
        setSelectedRow(index);
    }

    //웹사용권한 표시
    const webPermission = [
        { id: 0 , name:"사용 권한 없음"},
        { id: 1 , name:"사용 권한 있음"}
    ]
    const name_webPermission = (index) => {
        const name = webPermission.find(item => item.id == index);
        return name ? name.name : '정보 없음';
    };
    //공유 문제 사용 표시
    const sharingPermission = [
        { id: 0 , name:"공유 문제 사용불가"},
        { id: 1 , name:"공유 문제 사용 중"}
    ]
    const name_sharing = (index) => {
        const name = sharingPermission.find(item => item.id == index);
        return name ? name.name : '정보 없음';
    };

    //웹 사용 권한 전환
    const handleWebPermission = () =>{
        console.log("handleWebPermission")
        const selecteduser = userData[selectedRow]
        const result = window.confirm(`선택된 학원 (${selecteduser.workname})에 사용 권한을 전환시키겠습니까?`);
            if (result) {
                axios({
                    url : `${back_ip_url}/webPermission`,
                    data : {
                        userId : selecteduser.id,
                        webPermisson : selecteduser.webPermission
                    },
                    withCredentials: true,
                    method: "POST"
                }).then(response=>{
                    if (response.status === 200){
                        alert(response.data)
                        setSelectedRow()
                        fetchUserData()
                    }
                    if (response.status === 202){
                        alert(response.data)          
                    }
                }).catch(error => 
                    console.error('Error:', error));
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
    }

    //뭉클 제공 문제 권한
    const handleItemSharing = () =>{
        console.log("handleItemSharing")
        const selecteduser = userData[selectedRow]
        const result = window.confirm(`선택된 학원 (${selecteduser.workname})에 문제 권한을 전환시키겠습니까?`);
            if (result) {
                axios({
                    url : `${back_ip_url}/itemSharing`,
                    data : {
                        userId : selecteduser.id,
                        itemSharing : selecteduser.sharing
                    },
                    withCredentials: true,
                    method: "POST"
                }).then(response=>{
                    if (response.status === 200){
                        alert(response.data)
                        setSelectedRow()
                        fetchUserData()
                    }
                }).catch(error => 
                    console.error('Error:', error));
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
    }


return userInfo.id == "MK0927" ?(
    <div>
    <button onClick={logCheck}>LOG CHECK</button>
    <div>
        <h2>관리자 페이지</h2>
    </div>
    <div className="classmanager_admin">
        <table id="dataTable" style={{ width: "100%" , height: "730px"}}>
        <thead>
            <tr className="student user_info">
            <th style={{ height: "50px" }}>학원명</th>
            <th style={{ height: "50px" }}>ID</th>
            <th style={{ height: "50px" }}>이름</th>
            <th style={{ height: "50px" }}>이메일</th>
            <th style={{ height: "50px" }}>웹 사용 권한</th>
            <th style={{ height: "50px" }}>기본 문제 제공</th>
            </tr>
        </thead>
        <tbody>
            {userData
            ? userData.map((user, index) => (
                <tr
                    key={user.student_id}
                    style={{
                    height: "32px",
                    backgroundColor: selectedRow === index ? "lightblue" : "white",
                    }}
                    onClick={() => handleRowClick(index)}
                >
                    <td>{user.workname}</td>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{name_webPermission(user.webPermission)}</td>
                    <td>{name_sharing(user.sharing)}</td>
                </tr>
                ))
            : "강의 정보 불러오는 중..."}
        </tbody>
        </table>
        <div className="bottomBtn_admin">
        <button onClick={handleWebPermission} disabled={selectedRow==null} className="buttonA btnColorA" style={{ width: "300px", height: "35px", fontSize: "17px" }}>
            웹 사용 권한 전환
        </button>
        <button onClick={handleItemSharing} disabled={selectedRow==null} className="buttonA btnColorA" style={{ width: "300px", height: "35px", fontSize: "17px" }}>
            뭉클 문제 권한 전환
        </button>
        </div>
    </div>
    </div>
        ): null;
}

export default Admin
