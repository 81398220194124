import axios from 'axios';
import { useState , useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './StudentCheck.css';


export default function StudentCheck(){

    const back_ip_url = process.env.REACT_APP_SERVER_URL

    const [items, setItems] = useState([]);
    const [worknames, setWorknames] = useState([]);
    const [workname,setWorkname]= useState('');
    const [studentName,setStudentName]= useState('');
    const [phoneNumber,setPhoneNumber]= useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const workname2 = queryParams.get('workname');
    const id2 = queryParams.get('id');
    //?workname='' 으로 받으면 workname2에 저장됨

    const checkWorkname = (index) =>{
        console.log("workname log",index , items.map(index))
    }

    useEffect(() => {
        // 데이터를 가져오는 비동기 함수 (예: API 호출)
        const fetchUserData = () => {
            axios({
                method: "POST",
                  url: `${back_ip_url}/fetchUserData`,
                  withCredentials : true,
              })
              .then(response => {
                if(response.status === 200){
                    setItems(response.data);
                    const worknameList = response.data.map(item => item.workname);
                    setWorknames(worknameList);
                }
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        };

        fetchUserData();
    }, []);

    //학생 정보 확인
    const checkStudent = () =>{
        const matchingId = items.find(item => item.workname === workname)?.id || null;
        console.log("matchingId",matchingId)
        axios({
            method: "POST",
              url: `${back_ip_url}/checkStudent`,
              withCredentials : true,
              data:{
                user_id : id2,
                student_name : studentName,
                phone_number : phoneNumber,
              }
          })
          .then(response => {
            if(response.status === 200){
            window.open('/StudentMarking', '_self');

            }
            if(response.status === 202){
                alert("이름 또는 전화번호를 확인해주세요!")
            }
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }

    //로그 확인 logcheck
    const logcheck = () =>{
        console.log("workname",workname)
        console.log("studentName",studentName)
        console.log("phoneNumber",phoneNumber)
        console.log("items",items)
        console.log("worknames",worknames)
        console.log("workname2",workname2)
        console.log("id2",id2)
    }

    function oninputPhone(target) {
        target.value = target.value
            .replace(/[^0-9]/g, '')
            .replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, "$1-$2-$3");
    }
return(
    <div>
        <div className='check_main'>
            <h1>채점 로그인(학생용)</h1>
            {/* 학원선택 */}
            {/* 현재 아이디 : {id2} */}
            {/* <div>
                <select className='selectA' onChange={(e) => setWorkname(e.target.value)}>
                    <option value="" >학원 선택</option>
                    {worknames.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                        ))}
                </select>
            </div> */}
            {/* 학생이름 입력 + 전화번호*/}
            <input className="inputB"type="text" placeholder='학생 이름' onChange={(e)=>setStudentName(e.target.value)}></input>
            <input className="inputB"type="text" onInput={(e) => oninputPhone(e.target)} maxlength="13" placeholder='010-xxxx-xxxx'onChange={(e)=>setPhoneNumber(e.target.value)}></input>
            <button onClick={checkStudent} className="buttonA btnColorA confirm_btn" style={{height : '50px',fontSize:"25px"}}>로그인</button>
            <button onClick={logcheck}>logcheck</button>
        </div>
    </div>
)
}